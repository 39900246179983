import React from 'react';
import { AppLayout, AppLayoutContent } from '@/components/AppLayout';
import { DeclarationTabContent } from './DeclarationTabContent';
import { PageTab } from '@/components/PageTab';
import { useMUITabController } from '@/hooks/useMUITabController';
import { RDVTabContent } from './RDVTabContent';
import { Box, Skeleton, Typography } from '@mui/material';
import { CardButtonSkeleton } from '@/components/CardButton';
import { Spacer } from '@/components/Spacer';
import { ParcoursPublicQuery } from '@/query';

export const DeclarationPage: React.FC = () => {
  const parcoursPublic = ParcoursPublicQuery.useIndex();
  const hasAtLeastOneParcours = (parcoursPublic.data?.length ?? 0) > 0;
  const isInitializedFeatures = parcoursPublic.isFetched;

  const tabController = useMUITabController({
    initialValue: 'declare' as 'declare' | 'rdv',
    items: [
      { label: 'Déclarer', value: 'declare' },
      ...(hasAtLeastOneParcours ? [{ label: 'Solliciter un RDV', value: 'rdv' }] : []),
    ],
  });

  const { children, ...tabsProps } = tabController.getTabsProps();

  const renderFeatureContent = () => {
    return (
      <React.Fragment>
        {tabController.control.items.length > 0 ? <PageTab {...tabsProps} items={tabController.control.items} /> : null}
        {
          {
            declare: <DeclarationTabContent />,
            rdv: <RDVTabContent />,
          }[tabController.control.value]
        }
      </React.Fragment>
    );
  };

  return (
    <AppLayout>
      <AppLayoutContent requireAuthentication contentTitle={'KERij simplifie vos démarches'}>
        {isInitializedFeatures && renderFeatureContent()}
        {!isInitializedFeatures && (
          <Box>
            <Typography height={32} pt={1}>
              <Skeleton width={210} />
            </Typography>
            <Spacer spacing={4} />
            <CardButtonSkeleton avatar />
            <Spacer spacing={3} />
            <CardButtonSkeleton avatar />
            <Spacer spacing={3} />
            <CardButtonSkeleton avatar />
            <Spacer spacing={3} />
          </Box>
        )}
      </AppLayoutContent>
    </AppLayout>
  );
};
