import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ActionButton, ActionButtonProps } from '../ActionButton';
import type { EmployeeInformation } from '@/types';
import { AppLogo } from '../AppLogo';
import { Spacer } from '../Spacer';
import { useAppVariant } from '@/hooks/useAppVariant';

export function Verify() {
  const appVariant = useAppVariant();

  const state = useLocation();
  const navigate = useNavigate();

  const locationState: Record<'salarie' | 'hasAccount', any> | undefined =
    typeof state.state === 'object' && state.state != null ? state.state : undefined;

  const employee: Partial<EmployeeInformation> | undefined = locationState == null ? undefined : locationState.salarie;
  const accountFound = locationState == null ? false : Boolean(locationState.hasAccount);

  useEffect(() => {
    if (employee == null) navigate('/', { replace: true });
  }, []);

  const renderItem = (parameters: { label: string; value: string | undefined }) => (
    <Box display={'flex'} mt={1}>
      <Typography lineHeight={'normal'}>{parameters.label} :</Typography>
      <Typography lineHeight={'normal'} fontWeight={600} pl={1}>
        {parameters.value}
      </Typography>
    </Box>
  );

  const renderActions = (parameters: { label: string; onClick: ActionButtonProps['onClick'] }) => (
    <Box display={'flex'} justifyContent={'center'} mb={2}>
      <Box width={350}>
        <ActionButton
          color="primary"
          actionName="validate"
          label={parameters.label}
          startIcon={null}
          onClick={parameters.onClick}
          fullWidth
        />
        <ActionButton
          actionName="previous"
          label={"Ce n'est pas moi"}
          startIcon={null}
          onClick={() => navigate('/signup', { replace: true })}
          fullWidth
        />
      </Box>
    </Box>
  );

  return (
    <Box pl={4} pr={4}>
      <Spacer spacing={4} />

      <Box width={'100%'} textAlign={'center'}>
        <AppLogo variant="light-alt" />
      </Box>

      <Spacer spacing={appVariant.medium ? 3 : 4} />

      <Typography fontWeight={600} fontSize={20} textAlign={'center'} lineHeight={'normal'}>
        Vérification des informations
      </Typography>

      <Spacer spacing={3} />

      <Card sx={(theme) => ({ maxWidth: appVariant.small ? 420 : undefined, margin: 'auto' })} color="surface">
        <CardContent>
          <Typography fontWeight={600} fontSize={14} lineHeight={'normal'} mb={2}>
            Nous avons trouvé une correspondance dans notre base de données :
          </Typography>

          {renderItem({
            label: 'Nom Prénom',
            value: employee ? `${employee?.lastname} ${employee?.firstname}` : 'Inconnu',
          })}
          {renderItem({ label: 'Date de naissance', value: employee?.birthday ?? 'Inconnue' })}
          {renderItem({ label: 'Adresse', value: employee?.address1 ?? 'Inconnue' })}
          {renderItem({ label: 'Localité', value: employee?.locality ?? 'Inconnue' })}
          {renderItem({ label: 'Code Postal', value: employee?.zipcode ?? 'Inconnu' })}
          {renderItem({ label: 'Societé', value: employee?.business?.name ?? 'Inconnue' })}
        </CardContent>
      </Card>

      <Spacer spacing={4} />

      {accountFound && (
        <React.Fragment>
          <Box display={'flex'} justifyContent={'center'}>
            <Box maxWidth={420}>
              <Typography fontWeight={600} fontSize={14} textAlign={'center'} lineHeight={'normal'}>
                Nous avons trouvé un compte déjà présent dans notre base de données. Veuillez l&apos;utiliser pour vous
                connecter
              </Typography>
              <Typography lineHeight={'normal'} textAlign={'center'} mt={1}>
                L&apos;identifiant est : <strong>{employee?.employee_login?.email}</strong>
              </Typography>
            </Box>
          </Box>

          <Spacer spacing={4} />
          {renderActions({ label: 'Retour à la page de connexion', onClick: () => navigate('/login') })}
        </React.Fragment>
      )}

      {!accountFound && (
        <React.Fragment>
          <Typography fontWeight={600} fontSize={20} textAlign={'center'} lineHeight={'normal'}>
            Confirmez-vous ces informations ?
          </Typography>

          <Spacer spacing={2} />
          {renderActions({
            label: 'Je confirme',
            onClick: () => navigate('/createpassword', { state: state.state }),
          })}
        </React.Fragment>
      )}
    </Box>
  );
}
