import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../DashboardRH.css';
import './Admin.css';
import axios from '../../../lib/axios';
import { tous } from '@/components/ServiceThemeIcon';
import useCheckRhAuthentication from '../../../shared/hooks/chechRhAuthentication.hooks';
import isAuthorized from '../../../hooks/isAuthorized';
import { rhAdminGetService } from '../../../services/rh/admin';
import { useAppSelector } from '@/store';
import { PermissionBox } from '@/components/PermissionBox';
import {
  Service,
  ServiceDocumentType,
  ServiceReferenceType,
  ServiceTheme,
  ServiceThemeIcon,
  ServiceThemeLabel,
} from '@/types';
import { ServiceList } from '@/components/ServicesList';
import { FilterInput, FilterInputElement } from '@/components/FilterInput';
import { Autocomplete, Box, Divider, Grid, TextField, Typography, autocompleteClasses } from '@mui/material';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { usePermission } from '@/hooks/usePermission';
import { InputChangeEventHandler, InputChangeEvent } from '@/hooks/useInput';
import { ServiceForm, ServiceFormDocument, ServiceFormValue } from '@/components/ServicesForm';
import CustomModal from '@/components/base/CustomModal';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useToast } from '@/components/Toast';
import { RHPage } from '@/components/RHPage';
import { ActionButton } from '@/components/ActionButton';
import { IfPermission } from '@/components/IfPermission';
import { ADMIN_URL, PRO_URL } from '@/constants/urls.constants';

export const serviceMaxPerPage = 10;

export function AdminService() {
  const toast = useToast();
  const permission = usePermission();
  const navigate = useNavigate();

  const themes: FilterInputElement<ServiceTheme | undefined>[] = [
    { value: undefined, title: 'Tous', icon: tous },
    ...Object.values(ServiceTheme).map((theme) => ({
      value: theme,
      title: ServiceThemeLabel[`${theme}`],
      icon: ServiceThemeIcon[`${theme}`],
    })),
  ];

  const [valueSearch, setValueSearch] = useState<{ label: string; value?: string } | null>(null);
  const location = useLocation();
  const pathname = location.pathname;
  const [listService, setListService] = useState<Service[]>([]);
  const [theme, setTheme] = useState<ServiceTheme>();
  const [select, setSelect] = useState<string | null>(null);
  const [isLoadingServices, setIsLoadingServices] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [formValue, setFormValue] = useState<ServiceFormValue>();

  const listSelectBusiness = useAppSelector((state) => state.rhNavbarContext.listSelectBusiness);
  const selectedIdBusiness = useAppSelector((state) => state.rhNavbarContext.selectedIdBusiness);
  const [modalIsOpenEditService, setModalIsOpenEditService] = useState(false);

  const modelValues = useMemo(() => {
    const value = listService.find((value) => value.id === select);
    return value == null
      ? undefined
      : {
          service: {
            ...value,
            categories:
              value.categories
                ?.filter(({ type }) => type === ServiceReferenceType.Category)
                .map(({ reference }) => reference.id) ?? [],
            subcategories:
              value.categories
                ?.filter(({ type }) => type === ServiceReferenceType.Subcategory)
                .map(({ reference }) => reference.id) ?? [],
          },
        };
  }, [listService, select]);
  const filteredList = useMemo(
    () =>
      valueSearch == null || valueSearch.label.trim() === ''
        ? listService
        : listService.filter((service) => service.title.toLowerCase().includes(valueSearch.label.toLowerCase())),
    [listService, valueSearch],
  );

  const handleSelect: InputChangeEventHandler<InputChangeEvent, ServiceTheme | undefined> = (_, themeValue) => {
    if (isLoadingServices) return;
    setTheme(themeValue);
  };

  const getServicePages = async (
    theme: ServiceTheme | undefined,
    _selectedIdBusiness: typeof selectedIdBusiness,
    _listSelectBusiness: typeof listSelectBusiness,
    page = 1,
  ) => {
    if (page === 1) setListService([]);

    if (_listSelectBusiness.some((value) => value.isChecked) || _selectedIdBusiness) {
      const { data } = await rhAdminGetService({
        theme,
        page,
        per_page: serviceMaxPerPage,
        idBusiness: _listSelectBusiness.some((value) => value.isChecked)
          ? _listSelectBusiness?.filter((value) => value.isChecked).at(0)?.id
          : _selectedIdBusiness,
      });

      setListService((values) => [...values, ...data.services]);
      if (data.services.length === serviceMaxPerPage)
        await getServicePages(theme, _selectedIdBusiness, _listSelectBusiness, page + 1);
    }
  };

  const getServicesFiltered = async (theme: ServiceTheme | undefined) => {
    setIsLoadingServices(true);
    await getServicePages(theme, selectedIdBusiness, listSelectBusiness, 1);
    setIsLoadingServices(false);
  };

  const defineFormData = (data: ServiceFormValue['service']) => {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('short_description', data.short_description);

    if (data.email) formData.append('email', data.email);
    if (data.phone) formData.append('phone', data.phone);
    if (data.identifiant) formData.append('identifiant', data.identifiant);
    if (data.password) formData.append('password', data.password);
    if (data.address) formData.append('address', data.address);
    if (data.website) formData.append('website', data.website);
    if (data.provider) formData.append('provider', data.provider);
    if (data.adder) formData.append('adder', data.adder);
    if (data.funding != null) formData.append('funding', data.funding);
    if (data.promo) formData.append('promo', data.promo);
    if (data.address_complement) formData.append('address_complement', data.address_complement);
    if (data.zipcode) formData.append('zipcode', data.zipcode);
    if (data.city) formData.append('city', data.city);

    data.themes.forEach((themes) => formData.append('themes', themes));
    data.categories.forEach((category) => formData.append('categories', category));
    data.subcategories.forEach((subcategory) => formData.append('subcategories', subcategory));
    data.eligibilities.forEach((eligibility) => formData.append('eligibilities', eligibility));

    return formData;
  };

  const addServiceDocument = async (params: {
    id_service: string;
    id_business: string;
    type: ServiceDocumentType;
    file: File;
    extension?: string;
    // eslint-disable-next-line unicorn/consistent-function-scoping
  }) => {
    const formData = new FormData();
    formData.set('type', params.type);
    formData.set('id_service', params.id_service);
    formData.append('documents', params.file, `${params.type}.${params.extension || 'pdf'}`);
    await axios.post(`/api/rh/services/document`, formData, {});
  };

  const onSubmit = async (value: ServiceFormValue) => {
    setFormValue(value);
    setOpenConfirmation(true);
  };

  const addDocuments = async (service: Service, documents: ServiceFormDocument | undefined) => {
    try {
      if (documents) {
        if (documents.logo) {
          await addServiceDocument({
            id_service: service.id,
            id_business: service.id_business,
            type: ServiceDocumentType.Logo,
            file: documents.logo,
            extension: documents.logo.name.split('.').pop(),
          });
        }
        if (documents.referential) {
          await addServiceDocument({
            id_service: service.id,
            id_business: service.id_business,
            type: ServiceDocumentType.Referential,
            file: documents.referential,
            extension: 'pdf',
          });
        }
      }
    } catch {
      toast.present({ message: "Une erreur s'est produite lors de l'enregistrement des documents", severity: 'error' });
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setOpenConfirmation(false);

    if (formValue == null) {
      setSubmitting(false);
      return;
    }

    const { service, documents } = formValue;
    const formData = defineFormData(service);

    const selectedService = listService.find((data) => data.id === select);

    if (selectedService == null) return;
    const idBusiness = selectedService.id_business;
    const result = await axios.put<Service>(
      `/api/rh/admin/update_service/${idBusiness}/${selectedService.id}`,
      formData,
      {},
    );

    if (result && result.status === 200) {
      await addDocuments(result.data, documents);
      toast.present({ message: 'Le service a été modifié', severity: 'success' });
      getServicesFiltered(theme);
      setSubmitting(false);
      setModalIsOpenEditService(false);
    } else {
      toast.present({
        message: "Une erreur s'est produite. Rechargez votre navigateur et réessayez",
        severity: 'error',
      });
    }

    setOpenConfirmation(false);
  };

  useEffect(() => {
    if (isAuthorized(pathname)) getServicesFiltered(theme);
  }, [theme, selectedIdBusiness, listSelectBusiness]);

  useCheckRhAuthentication();

  return (
    <PermissionBox scope="service-management" action="access">
      <RHPage
        title="Catalogue de services"
        ContainerProps={{ sx: (theme) => ({ paddingLeft: '0 !important', paddingRight: '0 !important' }) }}
        actions={
          <IfPermission scope="service-management" action="edit">
            <ActionButton
              variant={'contained'}
              color="primary"
              actionName={'add'}
              onClick={() => navigate(PRO_URL.PREFIXE_PRO + ADMIN_URL.ADD_SERVICE)}
            />
          </IfPermission>
        }
        topElement={
          <Box ml={2.5} mr={2.5} width={460}>
            <Autocomplete
              value={valueSearch}
              options={listService.map((service) => ({ label: service.title, value: service.id || undefined }))}
              onChange={(e, newValue) => setValueSearch(newValue)}
              onInputChange={(e, newInputValue) => setValueSearch({ label: newInputValue })}
              renderInput={(_) => <TextField {..._} placeholder="Rechercher un service" />}
            />
          </Box>
        }
      >
        <div style={{ maxWidth: 'calc(100vw - 16.25rem)', padding: 20, paddingTop: 0 }}>
          <FilterInput
            items={themes}
            value={theme}
            onChange={handleSelect}
            disabled={isLoadingServices}
            sx={{
              borderStyle: 'solid',
              borderTopWidth: 0,
              marginBottom: 2,
            }}
          />

          {permission.isAdmin || permission.hasPermission('service-management', 'edit') ? (
            <ServiceList
              values={filteredList}
              isLoading={isLoadingServices}
              action={(value) => ({
                icon: faEdit,
                onClick: () => {
                  setSelect(value.id);
                  setModalIsOpenEditService(true);
                },
              })}
            />
          ) : (
            <ServiceList values={filteredList} isLoading={isLoadingServices} />
          )}
        </div>

        <CustomModal
          disabledBackdropDismiss={submitting}
          style={{ paddingBottom: '0' }}
          renderHeader={() => <>{'Modifier le service'}</>}
          open={modalIsOpenEditService || false}
          setOpen={setModalIsOpenEditService}
          renderFooter={() => (
            <Box>
              <ActionButton
                variant="text"
                actionName="cancel"
                disabled={submitting}
                onClick={() => setOpenCancel(true)}
                sx={{ mr: 1 }}
              />
              <ActionButton
                color="primary"
                variant="contained"
                actionName={'validate'}
                disabled={submitting}
                onClick={() => setSubmit(true)}
                sx={{ mr: 1 }}
              />
            </Box>
          )}
          renderContent={() => {
            const value = listService.find((value) => value.id === select);
            return value ? (
              <>
                <ServiceForm
                  value={modelValues}
                  submit={submit}
                  onSubmit={onSubmit}
                  onSubmitted={() => setSubmit(false)}
                />
                <ConfirmDialog
                  open={openConfirmation}
                  content="Êtes-vous sûr de vouloir valider vos modifications ?"
                  onConfirm={handleSubmit}
                  onCancel={() => setOpenConfirmation(false)}
                />

                <ConfirmDialog
                  open={openCancel}
                  content="Êtes-vous sûr de vouloir annuler vos modifications ?"
                  onConfirm={() => {
                    setModalIsOpenEditService(false);
                    setOpenCancel(false);
                  }}
                  onCancel={() => setOpenCancel(false)}
                />
              </>
            ) : (
              <Typography>Impossible de récupérer ce service</Typography>
            );
          }}
          maxWidth="md"
          sx={{
            '& .MuiDialog-paperWidthMd': {
              maxHeight: '80vh',
              maxWidth: 1080,
            },
          }}
        />
      </RHPage>
    </PermissionBox>
  );
}
