import axios from '@/lib/axios';
import { ParcoursPublic } from '@/types';

export const parcoursPublicFindAll = async () => {
  const response = await axios.get<parcoursPublicFindAll.Return>(`/api/employee/parcours`);
  return response.data?.data;
};

export namespace parcoursPublicFindAll {
  export type Parameters = void;
  export type Return = {
    data: Array<ParcoursPublic>;
    meta: {
      total: number;
    };
  };
}
