import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../../DashboardRH.css';
import { CardAbsenceHistory } from '../CardAbsenceHistory';
import useCheckRhAuthentication from '../../../../../../shared/hooks/chechRhAuthentication.hooks';
import { FilterSortAbsences } from '../filterSortAbsences.component';
import { Box, CircularProgress } from '@mui/material';
import { PermissionBox } from '@/components/PermissionBox';
import { ListCard } from '@/components/DashboardRH/ListCard';
import { RHPage } from '@/components/RHPage';
import type { absencesFindEmployeeHistory } from '@/services/rh/absences/absencesFindEmployeeHistory';
import { EmployeeHistoryQuery } from '@/query';

const HistoryAbsence = () => {
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(1);
  const [items, setItems] = useState<absencesFindEmployeeHistory.Return>([]);
  const [hasMore, setHasMore] = useState(true);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  const queryHistory = EmployeeHistoryQuery.useIndex({ search, page, per_page: 20 });

  const loadMore = useCallback(
    () => (hasMore && queryHistory.isFetched ? setPage((prevPage) => prevPage + 1) : undefined),
    [hasMore, queryHistory.isFetched],
  );

  const onSearch = useCallback(
    (value: string) => {
      if (value != search && queryHistory.isFetched) {
        setPage(1);
        setItems([]);
        setSearch(value);
        setHasMore(true);
      }
    },
    [queryHistory.isFetched, search],
  );

  useEffect(() => {
    if (queryHistory.data) {
      setItems((prevItems) => [...prevItems, ...queryHistory.data]);
      if (queryHistory.data.length === 0) setHasMore(false);
    }
  }, [queryHistory.data]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(
      (entries) => (entries[0].isIntersecting && hasMore ? loadMore() : undefined),
      { threshold: 1 },
    );
    if (lastElementRef.current) observerRef.current.observe(lastElementRef.current);
    return () => (observerRef.current ? observerRef.current.disconnect() : undefined);
  }, [loadMore, hasMore]);

  useCheckRhAuthentication();

  return (
    <PermissionBox scope="absence-management" action="access">
      <RHPage title="Historique des absences" topElement={<FilterSortAbsences setSearch={onSearch} />}>
        {queryHistory.isFetched && items.length === 0 && (
          <div className="text-center mt-5 mb-3 col-12 col-sm-10">
            <b>Aucune absence</b>
          </div>
        )}

        {items.map((employeeInformation, index) => {
          const { absences, ...rest } = employeeInformation;

          return (
            <ListCard key={index}>
              <CardAbsenceHistory absences={absences} employeeInformation={rest} />
            </ListCard>
          );
        })}

        <Box ref={lastElementRef} />

        {queryHistory.isLoading && (
          <div className={'text-center'}>
            <CircularProgress />
          </div>
        )}
      </RHPage>
    </PermissionBox>
  );
};

export default HistoryAbsence;
