import { absencesFindEmployeeHistory } from '@/services/rh/absences/absencesFindEmployeeHistory';
import { useQuery } from 'react-query';

export const EmployeeHistoryQuery = (() => {
  const useIndex = (parameters: absencesFindEmployeeHistory.Parameters) =>
    useQuery(
      ['employeeHistoryIndex', parameters],
      async () => {
        return absencesFindEmployeeHistory(parameters);
      },
      { keepPreviousData: true },
    );

  return {
    useIndex,
  };
})();
