import React, { useEffect } from 'react';
import { Feature, Notification, useAppDispatch } from '@/store';
import { useAppWorkspace } from '@/hooks/useAppWorkspace';
import { findEmployeeAccount } from './findEmployeeAccount';
import { findRHAccount } from './findRHAccount';
import { AccountProviderModal } from './AccountProviderModal';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useQueryClient } from 'react-query';

interface AccountProviderProps {
  children: React.ReactNode;
}

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const dispatch = useAppDispatch();
  const appWorkspace = useAppWorkspace();
  const { isAuthenticated } = useAuthentication(appWorkspace.value);
  const queryClient = useQueryClient();

  /**
   * Initialise la connexion salarié
   */
  async function initEmployee() {
    if (!appWorkspace.match('employee')) return;

    await findEmployeeAccount(dispatch);
    if (isAuthenticated) {
      await dispatch(Notification.load());
      await dispatch(Feature.load());
    }
  }

  /**
   * Initialise la connexion rh
   */
  async function initRH() {
    if (!appWorkspace.match('pro')) return;
    await findRHAccount(dispatch);
    if (isAuthenticated) {
      await dispatch(Feature.load());
    }
  }

  useEffect(() => {
    initEmployee().catch(console.error);
    initRH().catch(console.error);
  }, []);

  // When authenticated, refresh all views
  useEffect(() => {
    if (isAuthenticated) {
      queryClient.invalidateQueries();
    }
  }, [isAuthenticated]);

  /**
   * Vérifie la connexion
   */
  async function refreshEmployee() {
    if (appWorkspace.match('employee')) await findEmployeeAccount(dispatch);
  }

  useEffect(() => {
    setInterval(refreshEmployee, 900_000); // 15 minutes
  }, []);

  return <AccountProviderModal>{children}</AccountProviderModal>;
};
