import React from 'react';
import { useAppFeature } from '@/store';
import {
  BottomNavigationAction,
  bottomNavigationActionClasses,
  type BottomNavigationActionProps,
  styled,
} from '@mui/material';
import { Feature } from '@/types';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'clsx';

const componentName = 'NavbarMobileTab';
const defaultPath = '/demarches';

export interface NavbarMobileTabProps extends BottomNavigationActionProps {
  to?: string;
  label?: string;
  icon?: React.ReactNode;
  feature?: keyof Feature;
  /**
   * Active state. User current route if omitted.
   */
  active?: boolean | undefined;
}

export function NavbarMobileTab(props: NavbarMobileTabProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    className,
    to,
    label,
    feature,
    icon,
    onClick,
    active = to != null && (location.pathname === '/' ? to === defaultPath : location.pathname.startsWith(to)),

    ...otherProps
  } = props;
  const appFeature = useAppFeature();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick?.(event);
    if (to != null) {
      navigate(to);
    }
  };

  const action = (
    <ButtonNavigation
      className={classNames(componentName, className)}
      label={label}
      icon={<span className={`${componentName}__Icon`}>{icon}</span>}
      sx={(theme) => ({
        color: active ? theme.md3.sys.color.onSurface : theme.md3.sys.color.onSurfaceVariant,
        backgroundColor: active ? theme.md3.sys.color.primaryContainer : theme.md3.sys.color.white,
      })}
      onClick={handleClick}
      disableRipple
      disableTouchRipple
      {...otherProps}
    />
  );
  return feature == null || appFeature[feature] ? action : null;
}

const ButtonNavigation = styled(BottomNavigationAction)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  minWidth: 0,
  padding: 0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  [`& .${bottomNavigationActionClasses.label}`]: {
    ...theme.md3.sys.typescale.label.small,
    marginBottom: theme.spacing(1),
  },

  [`& .${componentName}__Icon`]: {
    fontSize: theme.spacing(3),
  },
}));
