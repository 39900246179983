import axios from '@/lib/axios';
import { Absence, EmployeeInformation } from '@/types';

export const absencesFindEmployeeHistory = async (params: absencesFindEmployeeHistory.Parameters) => {
  const response = await axios.get<absencesFindEmployeeHistory.Return>(`/api/rh/employee_histories`, { params });
  return response.data;
};

export namespace absencesFindEmployeeHistory {
  export interface Parameters {
    page: number;
    per_page: number;
    search?: string;
  }
  export interface Item extends EmployeeInformation {
    totalLastYearAbsences: number;
    totalLastYearAbsencesDays: number;
    absences: Array<Absence>;
  }
  export type Return = Array<Item>;
}
