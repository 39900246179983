import { RHMenuItem } from '@/components/DashboardRH/Layout';
import { PRO_URL, ADMIN_URL } from '@/constants/urls.constants';
import { RHPermissionScope } from '@/types';

/**
 * Défini le contenu du menu
 */
export function defineMenu(): RHMenuItem[] {
  return [
    {
      title: 'ABSENCES',
      permission: { scope: RHPermissionScope.AbsenceManagement, action: 'access' },
      items: [
        {
          text: 'Dossiers ouverts',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.PROGRESS_ABSENCE,
          permission: { scope: RHPermissionScope.AbsenceManagement, action: 'access' },
        },
        {
          text: 'Créer une absence',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.CREATE_HR_LEAVE,
          permission: { scope: RHPermissionScope.AbsenceManagement, action: 'edit' },
        },
        {
          text: 'Modifier une absence',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.EDIT_HR_LEAVE,
          permission: { scope: RHPermissionScope.AbsenceManagement, action: 'edit' },
        },
        {
          text: 'Supprimer une absence',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.DELETE_HR_LEAVE,
          permission: { scope: RHPermissionScope.AbsenceManagement, action: 'edit' },
        },
        {
          text: 'Extraction des absences',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.EXTRACTED_ABSENCES,
          permission: { scope: RHPermissionScope.AbsenceManagement, action: 'access' },
        },
        {
          text: 'Historique des absences',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.ABSENCE_HISTORY,
          permission: { scope: RHPermissionScope.AbsenceManagement, action: 'access' },
        },
        {
          text: 'Statistiques',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.STATS,
          permission: { scope: RHPermissionScope.Statistic, action: 'access' },
        },
      ],
    },
    {
      title: 'MANAGEMENT',
      permission: { scope: RHPermissionScope.Management, action: 'access' },
      items: [
        {
          text: 'Voir les parcours',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS,
          permission: { scope: RHPermissionScope.Management, action: 'access' },
        },
        {
          text: 'Créer un parcours',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS_CREATE,
          permission: { scope: RHPermissionScope.Management, action: 'access' },
        },
        {
          text: 'Historique des envois',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.PARCOURS_STATE,
          permission: { scope: RHPermissionScope.Management, action: 'access' },
        },
        {
          text: 'Centre de ressources',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.ENTRETIEN_LEGAL,
          permission: { scope: RHPermissionScope.Management, action: 'access' },
        },
      ],
    },
    {
      title: 'SERVICES',
      permission: { scope: RHPermissionScope.ServiceManagement, action: 'access' },
      feature: 'ServiceManagement',
      items: [
        {
          text: 'Visualiser le catalogue',
          url: PRO_URL.PREFIXE_PRO + ADMIN_URL.SERVICES,
          permission: { scope: RHPermissionScope.ServiceManagement, action: 'access' },
        },
        {
          text: 'Créer un service',
          url: PRO_URL.PREFIXE_PRO + ADMIN_URL.ADD_SERVICE,
          permission: { scope: RHPermissionScope.ServiceManagement, action: 'edit' },
        },
        // {
        //   text: 'Alimenter le bot',
        //   url: `${ExternalURL.Bot}/docs`,
        //   permission: { scope: RHPermissionScope.Bot, action: 'edit' },
        //   external: true,
        // },
        {
          text: 'Animer la plateforme',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.SERVICE_ANIMATION,
          permission: { scope: RHPermissionScope.ServiceAnimation, action: 'edit' },
        },
      ],
    },
    {
      title: 'GESTION',
      items: [
        {
          text: 'Collaborateurs',
          url: PRO_URL.PREFIXE_PRO + PRO_URL.COLLABORATEURS,
        },
      ],
    },
    {
      title: 'ADMINISTRATION',
      permission: { scope: 'admin', action: 'edit' },
      items: [
        {
          text: 'Utilisateurs',
          url: PRO_URL.PREFIXE_PRO + ADMIN_URL.USERS,
          permission: { scope: 'admin', action: 'edit' },
        },
        {
          text: 'Roles',
          url: PRO_URL.PREFIXE_PRO + ADMIN_URL.ROLES,
          permission: { scope: 'admin', action: 'edit' },
        },
      ],
    },
  ];
}
