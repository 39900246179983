import moment from 'moment';
import { fetchRhAction } from '../../services/rh';

export const getDataActions = async ({
  dateDebut,
  dateFin,
  search,
  listSelectBusiness,
  selectedIdBusiness,
  offset,
  limit,
}) => {
  if (
    (!dateDebut || /\d{4}-\d{2}-\d{2}/g.test(dateDebut)) &&
    (!dateFin || /\d{4}-\d{2}-\d{2}/g.test(dateFin)) &&
    selectedIdBusiness
  ) {
    if (
      /\d{4}-\d{2}-\d{2}/g.test(dateDebut) &&
      /\d{4}-\d{2}-\d{2}/g.test(dateFin) &&
      moment(dateDebut, 'YYYY-MM-DD').isSameOrAfter(moment(dateFin, 'YYYY-MM-DD'))
    ) {
      return [];
    } else {
      const response = await fetchRhAction({
        search: search,
        idBusinesses: JSON.stringify(listSelectBusiness.filter((value) => value.isChecked).map((value) => value.id)),
        idBusiness: selectedIdBusiness,
        offset: offset,
        limit: limit,
      });

      return response.data;
    }
  }
};
