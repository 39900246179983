import { translateVariable } from '@/components/VariableTranslate';
import { CustomText } from '../type';

export const variableRegex = /{{(.*?)}}/g;

export const parseVariable = (text: string | undefined, type?: Omit<CustomText, 'text'>): CustomText[] => {
  const textValue = typeof text === 'string' ? text : '';
  const parts = textValue.split(variableRegex);

  return parts.flatMap((part, index) => {
    if (index % 2 !== 1) return [{ text: part, ...type }];
    const trimPart = part.trim();
    const translatedVariable = translateVariable(trimPart);

    return [
      { text: '', ...type },
      {
        text: translatedVariable.label,
        variable: true,
        readOnly: true,
        error: translatedVariable.translated === false,
        ...type,
      },
      { text: ' ', ...type },
    ];
  });
};
